import React from 'react';
import {useWindowSize} from 'react-use';

// class Music extends Component {
//   componentDidMount() {
//     let h = 400;
//     let w = 360;
//     if (global.window && global.window.document) {
//       const content = global.window.document.getElementById("content");
//       const soundcloud = global.window.document.getElementById("soundcloud-embed");

//       if (content && soundcloud) {
//         var rect = content.getBoundingClientRect();
//         w = rect.width;
//         h = rect.height;
//         soundcloud.style.width = `${w}px`;
//         soundcloud.style.height = `${h}px`;
//         soundcloud.setAttribute('width', `${w}px`);
//         soundcloud.setAttribute('height', `${h}px`);
//       }
//     }
//   }
//   render() {
//     return (
//       <div className="content">
//         <iframe id="soundcloud-embed" width="100%" scrolling="yes" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/285800036&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=true" title="SoundCloud"></iframe>
//       </div>
//     )
//   }
// }

export default function Music() {
  const {width, height} = useWindowSize();

  const maxHeight = height > 850 ? 600 : height - 140;
  const maxWidth = width > 500 ? 500 : width;

  return (
    <div className="soundcloud-content">
      <iframe 
        id="soundcloud-embed"
        width={maxWidth}
        height={maxHeight}
        scrolling="yes" 
        frameBorder="no" 
        allow="autoplay" 
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/285800036&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=true" 
        title="SoundCloud">
      </iframe>
    </div>
  )
}
