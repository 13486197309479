import React, { Component } from 'react';

class Live extends Component {

  componentDidMount() {
    let h = 400;
    let w = 360;
    if (global.window && global.window.document) {
      const content = global.window.document.getElementById("content");
      const twitch = global.window.document.getElementById("twitch-embed");

      if (content && twitch) {
        var rect = content.getBoundingClientRect();
        w = rect.width;
        h = rect.height;
        twitch.style.width = `${w}px`;
        twitch.style.height = `${h}px`;

        new global.Twitch.Embed("twitch-embed", {
          width: w,
          height: h,
          theme: "dark",
          channel: "demidawed"
        });
      }
    }
  }

  render() {

    return (
      <div id="content" className="content">

        <div id="twitch-embed">
        </div>

      </div>
    )
  }
}

export default Live;
