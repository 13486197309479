import React, { Component } from 'react';

class QrCode extends Component {
  render() {
    return (
      <div className="content">

        <div className="soundcloud-container" style={{padding:'0'}}>

          <div className="brand-container small">
            <div className="brand">
              <div className="brand-logo"></div>
            </div>
          </div>

          <iframe title="Rollercoaster" width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/463955808&color=%23b339fe&auto_play=true&hide_related=true&show_comments=true&show_user=true&show_reposts=false&show_teaser=false&visual=true"></iframe>
        </div>

      </div>
    )
  }
}

export default QrCode;
