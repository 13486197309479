import React, { Component } from 'react';

class Merch extends Component {
  render() {
    return (
      <div className="content">

        <div style={{textAlign:'center',padding:'3rem'}}>
          Comming Soon
        </div>

      </div>
    )
  }
}

export default Merch;
