import React, { Component } from 'react';

import SoundcloudIcon from 'mdi-react/SoundcloudIcon';
import TwitterIcon from 'mdi-react/TwitterIcon';
import InstagramIcon from 'mdi-react/InstagramIcon';
import FacebookBoxIcon from 'mdi-react/FacebookBoxIcon';
import TwitchIcon from 'mdi-react/TwitchIcon';

class Home extends Component {
  render() {
    return (
      <div className="content homeContent">

        <div className="home-container">

          <h1>The electronic vibes of ...</h1>

          <div className="brand-container">
            <div className="brand">
              <div className="brand-logo"></div>
            </div>
          </div>

          <div className="service-container">
            <a href="https://soundcloud.com/demidawed/" className="service soundcloud" tabIndex={0} target="_blank" rel="noopener noreferrer">
              <div className="service-logo"><SoundcloudIcon /></div>
              <div className="service-text">Sound Cloud</div>
            </a>
            <a href="https://www.twitch.tv/demidawed" className="service twitch" tabIndex={0} target="_blank" rel="noopener noreferrer">
              <div className="service-logo"><TwitchIcon /></div>
              <div className="service-text">Twitch</div>
            </a>
            <a href="https://twitter.com/demidawed/" className="service twitter" tabIndex={0} target="_blank" rel="noopener noreferrer">
              <div className="service-logo"><TwitterIcon /></div>
              <div className="service-text">Twitter</div>
            </a>
            <a href="https://www.facebook.com/demidawed/" className="service facebook" tabIndex={0} target="_blank" rel="noopener noreferrer">
              <div className="service-logo"><FacebookBoxIcon /></div>
              <div className="service-text">Facebook</div>
            </a>
            <a href="https://www.instagram.com/demidawed/" className="service instagram" tabIndex={0} target="_blank" rel="noopener noreferrer">
              <div className="service-logo"><InstagramIcon /></div>
              <div className="service-text">Instagram</div>
            </a>
          </div>
        </div>

      </div>
    )
  }
}

export default Home;
