import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
      <div className="content">

        <div style={{padding:'1rem 3rem'}}>
          <h4>Who Am I</h4>
          <p>I like music</p>
        </div>

      </div>
    )
  }
}

export default About;
