import React, { Component } from 'react';

class Contact extends Component {
  render() {
    return (
      <div className="content">

        <div className="contactForm">

          <div className="contact-content">
            <div className="brand-container small">
              <div className="brand">
                <div className="brand-logo"></div>
              </div>
            </div>
            <h3>What do you need?</h3>
            <ul>
              <li>Samples</li>
              <li>Sound Design</li>
              <li>YouTube Music</li>
              <li>Soundtracks</li>
              <li>Collab</li>
              <li>Just for Fun</li>
            </ul>
          </div>

          <form method="POST" action="https://formspree.io/demidawed@gmail.com">
            <input type="hidden" name="_subject" value="DemiDawed.com Contact Page" />
            <input type="text" name="_gotcha" style={{display:'none'}} />

            <div className="formGroup">
              <label htmlFor="_replyto">Email Address</label>
              <input type="email" name="_replyto" autoComplete="true" />
            </div>

            <div className="formGroup">
              <label htmlFor="message">Message</label>
              <textarea name="message"></textarea>
            </div>

            <div className="formActions">
              <input type="submit" name="submit" value="Submit" />
            </div>

          </form>

        </div>

      </div>
    )
  }
}

export default Contact;
