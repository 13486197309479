import React, { Component } from 'react';

import Home from './Home';
import Live from './Live';
import Music from './Music';
import Merch from './Merch';
import About from './About';
import Contact from './Contact';
import QrCode from './QrCode';

const pages = {
  home: <Home />,
  live: <Live />,
  music: <Music />,
  merch: <Merch />,
  about: <About />,
  contact: <Contact />,
  qrcode: <QrCode />,
}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      view: 'home'
    }
  }

  handleHashChange = () => {
    if (global.window && global.window.location) {
      const view = global.window.location.hash;
      this.setState({ view }); // needed to call render again
    }
  }

  componentDidMount() {
    global.window.addEventListener("hashchange", this.handleHashChange, false);
  }

  componentWillUnmount() {
    global.window.removeEventListener("hashchange", this.handleHashChange, false);
  }

  navigate = (view) => {
    if (global.window && global.window.location) {
      global.window.location.hash = view;
      this.setState({ view }); // needed to call render again
    }
  }

  getView = () => {
    if (global.window && global.window.location) {
      const view = global.window.location.hash.replace('#', '');
      return pages[view] ? view : 'home';
    }
    return 'home';
  }

  render() {
    const view = this.getView();

    return (
      <div className="app">

          <div className="nav">
            <div className="link" tabIndex={0} role="button" onClick={() => this.navigate('home')}>
              Home
            </div>
            <div className="link" tabIndex={0} role="button" onClick={() => this.navigate('live')}>
              Live
            </div>
            <div className="link" tabIndex={0} role="button" onClick={() => this.navigate('music')}>
              Music
            </div>
            {/* <div className="link" tabIndex={0} role="button" onClick={() => this.navigate('merch')}>
              Merch
            </div>
            <div className="link" tabIndex={0} role="button" onClick={() => this.navigate('about')}>
              About
            </div> */}
            <div className="link" tabIndex={0} role="button" onClick={() => this.navigate('contact')}>
              Contact
            </div>
          </div>

          { pages[view] }

          <div className="footer">
            <div>
              &copy;{ (new Date()).getFullYear() } DemiDawed.com
            </div>
            <div>
              Powered By: <a href="https://www.fvcsolutions.com/" target="_blank" rel="noopener noreferrer">FVC Solutions</a>
            </div>
          </div>

        <div className="seriously"><span>I just can't operate under these conditions</span></div>

      </div>
    );
  }
}

export default App;
